import { useEffect, useState } from "react";
import styled from "styled-components";

import "../style/window.css";
import { ReactComponent as Flower } from "../assets/flower-pot-ella.svg";
import Mountains from "../assets/mountains.png";

import TextTransition, { presets } from "react-text-transition";
import { Link } from "react-scroll";

const StyledIntro = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: calc(10px + 2vmin);
  color: var(--light);
  @media only screen and (max-width: 950px) {
    position: relative;
    height: 100%;
    min-height: 100vh;
  }
  .down-arrow {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    color: white;
    text-align: center;
    cursor: pointer;
    transition: 0.2s ease-in;
    &:hover {
      transform: translate(-50%, 5px);
    }
  }
`;

const Title = styled.h1`
  font-size: 40px;
  text-align: center;
  color: var(--light);
  font-weight: 400;
  span {
    color: var(--c-one);
  }
  @media (max-width: 768px) {
    font-size: 30px;
    margin-bottom: 10px;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  padding-left: 100px;
  @media (max-width: 1250px) {
    width: 40%;
    padding-left: 30px;
  }
  @media (max-width: 950px) {
    width: 350px;
    position: absolute;
    top: 70%;
    left: 52%;
    z-index: 1;
    padding: 0;
    transform: translateX(-50%);
  }

  @media (max-width: 600px) {
    width: 275px;
  }
`;

const RightSide = styled.div`
  width: 50%;
  position: relative;
  h2 {
    font-weight: 400;
  }
  @media (max-width: 1250px) {
    width: 60%;
  }
  @media (max-width: 950px) {
    width: 100%;
  }
`;

const WindowBackground = styled.div`
  animation: skyChange 10s linear infinite;
  height: 70%;
  position: relative;
  clip-path: polygon(
    0 81%,
    2% 0,
    100% 0,
    100% 99%,
    36% 86%,
    0 83%,
    6% 75%,
    6% 74%
  );
  @media (max-width: 600px) {
    clip-path: unset;
    height: 60%;
  }
`;

const WindowFrame = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--light);
  z-index: 0;
  clip-path: polygon(
    0 84%,
    0% 0%,
    6% 0,
    6% 76%,
    0 83%,
    6% 75%,
    100% 92%,
    101% 101%
  );
  @media (max-width: 600px) {
    clip-path: polygon(0px 100%, 0% 0%, 5% 0px, 4.64% 94%, 100% 94%, 100% 100%);
    z-index: 2;
  }
`;

const StyledFlower = styled.div`
  svg {
    height: 140px;
    position: absolute;
    bottom: 18%;
    left: 76px;
    width: 135px;
    user-select: none;
    *:not(.st3, .st4) {
      animation: rotate 5s infinite;
      animation-delay: 0s;
    }
    @media (max-width: 950px) {
      height: 100px;
    }
    @media (max-width: 600px) {
      height: 80px;
      bottom: 10px;
      left: 0px;
      z-index: 2;
    }
  }
`;

const StyledMountains = styled.img`
  position: absolute;
  right: 0;
  bottom: 42px;
  z-index: -1;
  width: 460px;
  height: auto;
  user-select: none;
  user-drag: none;
  @media (max-width: 600px) {
    height: auto;
    width: 210px;
    z-index: 0;
    bottom: 18px;
  }
`;

const FloatingTitle = styled.h2`
  position: absolute;
  bottom: 70px;
  right: 50px;
  margin: 0;
  font-size: 45px;
  max-width: 660px;
  text-align: right;
  color: rgba(189, 195, 199, 0.4);
  font-weight: lighter !important;
  &::selection {
    background-color: var(--c-one);
  }
  span {
    color: var(--c-two);
    &::selection {
      color: var(--dark);
    }
  }
  @media (max-height: 800px) {
    font-size: 26px !important;
  }
  @media (max-width: 1700px) {
    font-size: 40px;
    right: 30px;
    max-width: 560px;
  }
  @media (max-width: 1480px) {
    font-size: 35px;
    right: 30px;
    max-width: 480px;
  }
  @media (max-width: 950px) {
    display: none;
  }
`;

const SubtitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  user-select: none;
  p {
    font-size: 26px;
  }
`;

const IntroHome = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 2000);
    return () => clearTimeout(intervalId);
  }, []);

  const TEXTS = ["visualisations", "experiences", "digital products"];

  return (
    <StyledIntro className="section section--home">
      <LeftSide>
        <Title>
          Hi, I'm <span>Ella</span>.
        </Title>
        <SubtitleWrapper>
          <p
            style={{ marginRight: "6px", color: "var(--c-one)" }}
            className="top"
          >
            I design
          </p>
          <TextTransition
            springConfig={presets.wobbly}
            style={{ fontSize: "24px" }}
          >
            {TEXTS[index % TEXTS.length]}
          </TextTransition>
        </SubtitleWrapper>
      </LeftSide>
      <RightSide>
        <WindowBackground className="window-background">
          <WindowFrame className="window-frame" />
          <div className="sun" title="Sun | designwithella.com">
            <div className="ray_box">
              <div className="ray ray1"></div>
              <div className="ray ray2"></div>
              <div className="ray ray3"></div>
              <div className="ray ray4"></div>
              <div className="ray ray5"></div>
              <div className="ray ray6"></div>
              <div className="ray ray7"></div>
              <div className="ray ray8"></div>
              <div className="ray ray9"></div>
              <div className="ray ray10"></div>
            </div>
          </div>
          <div className="clouds">
            <div className="cloud c1" title="Cloud | designwithella.com"></div>
            <div className="cloud c2" title="Cloud | designwithella.com"></div>
          </div>
          <StyledFlower>
            <Flower title="Flower | designwithella.com" />
          </StyledFlower>
          <StyledMountains
            src={Mountains}
            title="Mountains | designwithella.com"
            alt="mountains"
          />
        </WindowBackground>
        <FloatingTitle>
          Design a <span>great experience</span> for your users on the web and
          mobile platforms
        </FloatingTitle>
      </RightSide>
      <Link
        className="down-arrow"
        to={"about-ella"}
        spy={true}
        smooth={true}
        duration={900}
        delay={100}
        offset={-120}
        title="About Ella"
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polyline
            points="10,30 50,70 90,30"
            stroke={"var(--c-two)"}
            strokeWidth="5"
            fill="none"
          />
        </svg>
      </Link>
    </StyledIntro>
  );
};

export default IntroHome;
