import styled from "styled-components";

import Email from "../assets/email.png";
// import Instagram from "../assets/instagram.png";
import Linkedin from "../assets/linkedin.png";
// import Clubhouse from "../assets/clubhouse.png";
// import Behance from "../assets/behance.png";
// import DesignRush from "../assets/dr.png";

const StyledContact = styled.div`
  position: relative;
  /* height: 50vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: var(--light);
  background-color: transparent;
  z-index: 1;
  @media only screen and (max-width: 950px) {
    /* height: 75vh; */
  }
  @media (max-width: 600px) {
    /* height: 90vh; */
  }
  .calendly {
    margin-bottom: 80px;
    color: var(--dark);
    p {
      font-size: 20px;
      margin-top: 20px;
      padding: 0 20px;
      line-height: 1.5;
    }
    a {
      margin-left: 40px;
      transition: 0.2s ease-in;
      font-weight: 500;
      position: relative;
      text-decoration: none;
      color: var(--dark-green);
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -3px;
        left: 0;
        background: var(--dark-green);
        transition: 0.2s ease-in;
      }
    }
  }
`;

const Title = styled.h4`
  font-size: 32px;
  text-align: center;
  color: var(--c-one);
  margin: 0;
  font-weight: 500;
  span {
    background: var(--c-two);
    color: var(--dark);
    &::selection {
      background: var(--light);
      color: var(--c-one);
    }
  }
  @media (max-width: 768px) {
    font-size: 30px;
    margin-bottom: 0px;
  }
  &.top-title {
    color: var(--dark-green);
  }
`;
const IconsWrap = styled.div`
  margin-top: 20px;
`;

const LinkWrap = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: var(--dark);
  margin-top: 10px;
  p {
    margin: 0;
    transition: 0.2s ease-in;
    margin-left: 10px;
    font-size: 16px;
    position: relative;
    font-weight: 400;
    &:after {
      content: "";
      transition: 0.2s ease-in;
    }
  }
  img {
    height: auto;
    max-width: 28px;
    transition: 0.2s ease-in;
    padding: 4px;
  }
  &:hover {
    p {
      color: var(--c-two);
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -2px;
        left: 0;
        background: var(--c-two);
      }
    }
    img {
      background: var(--c-one);
    }
  }
`;

const TopSection = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 70px 0;
`;

const Footer = styled.footer`
  width: 100%;
  .love {
    background: var(--dark-green);
    font-size: 13px;
    padding: 10px 0;
    .heart {
      color: var(--c-one);
    }
    &::selection {
      background: var(--c-one);
    }
  }
  .copyrights {
    background: var(--dark);
    font-size: 13px;
    padding: 5px 0;
    span {
      color: var(--c-one);
    }
  }
`;

const FooterSection = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <StyledContact className="section section--footer" id="contact-now">
      <TopSection className="top-section">
        <div className="calendly">
          <Title className="top-title">Starting a new project?</Title>
          <p>
            <a
              href="https://calendly.com/design-with-ella/intro"
              target="_blank"
              rel="noopener noreferrer"
            >
              Book a free
            </a>{" "}
            15-minute Zoom chat now to discuss details.
          </p>
        </div>
        <Title>
          Let's get <span>in touch</span>
        </Title>
        <IconsWrap>
          <LinkWrap
            href="https://www.linkedin.com/in/cerasela-tutuianu"
            title="LinkedIn"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
          >
            <img src={Linkedin} alt="LinkedIn" width="28" height="28" />
            <p>/in/designwithella</p>
          </LinkWrap>
          <LinkWrap
            href="mailto:hello@designwithella.com?subject=Contact Ella"
            title="Email Ella at hello@designwithella.com"
            className="icon-link"
          >
            <img src={Email} alt="Email Ella" width="28" height="28" />
            <p>hello@designwithella.com</p>
          </LinkWrap>
          {/* <LinkWrap
            href="https://www.behance.net/designwithella"
            title="Behance"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
          >
            <img src={Behance} alt="behance" width="28" height="28" />
            <p>/designwithella</p>
          </LinkWrap>
          <LinkWrap
            href="https://www.designrush.com/agency/profile/design-with-ella"
            title="You can find me under Top Web Design Freelancers"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
          >
            <img src={DesignRush} alt="behance" width="28" height="28" />
            <p>DesignRush</p>
          </LinkWrap> */}
          {/* <LinkWrap
            href="https://www.instagram.com/designwithella"
            title="Instagram"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
          >
            <img src={Instagram} alt="Instagram" />
            <p>@designwithella</p>
          </LinkWrap> */}
          {/* <LinkWrap
            href="https://clubhouse.com/@ellatut"
            title="Clubhouse"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
          >
            <img src={Clubhouse} alt="clubhouse" width="28" height="28" />
            <p>@ellatut</p>
          </LinkWrap> */}
        </IconsWrap>
      </TopSection>
      <Footer>
        <div className="love">
          Design with <span className="heart">❤ </span>
          <span>@designwithella</span>
        </div>
        <div className="copyrights">All rights reserved © {year}</div>
      </Footer>
    </StyledContact>
  );
};

export default FooterSection;
