import styled from "styled-components";
import { ReactComponent as Icon } from "../assets/icon.svg";

// import { NavLink } from "react-router-dom";

const HeaderWrap = styled.header`
  background: var(--light);
  width: 100%;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 11%);
  font-weight: 600;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const LeftSide = styled.section`
  padding-left: 30px;
  user-select: none;
  display: flex;
  @media (max-width: 768px) {
    padding-left: 10px;
  }
`;

const StyledIcon = styled.div`
  margin-right: 10px;
  @media (max-width: 768px) {
    margin-right: -10px;
  }
  svg {
    width: 30px;
    height: auto;

    @media (max-width: 1250px) {
      width: 25px;
    }

    @media (max-width: 768px) {
      width: 20px;
      margin-right: 20px;
    }
    #Layer_2 {
      #Capa_1 {
        #left-eye,
        #right-eye {
          transition: 0.2s ease-in;
        }
      }
    }
  }
  &:hover {
    svg {
      #Layer_2 {
        #Capa_1 {
          #left-eye,
          #right-eye {
            fill: var(--c-three);
          }
        }
      }
    }
  }
`;

const StyledText = styled.a`
  color: var(--dark);
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: 0.2s ease-in;
  font-weight: 500;
  span {
    transition: 0.2s ease-in;
    color: var(--dark-green);
  }
  &:hover {
    color: var(--dark-green);
    span {
      color: var(--c-one);
    }
  }
  @media (max-width: 1250px) {
    font-size: 15px;
  }

  /* @media (max-width: 768px) {
    display: none;
  } */
`;

// const RightSide = styled.nav`
//   padding-right: 30px;
//   display: flex;

//   a {
//     margin-left: 40px;
//     transition: 0.2s ease-in;
//     font-weight: 500;
//     position: relative;
//     text-decoration: none;
//     color: var(--dark);
//     &:after {
//       content: "";
//       position: absolute;
//       width: 0%;
//       height: 2px;
//       bottom: -3px;
//       left: 0;
//       background: var(--dark-green);
//       transition: 0.2s ease-in;
//     }
//     @media (max-width: 768px) {
//       margin-left: 20px;
//       &:first-of-type {
//         margin-left: 0;
//       }
//     }
//   }

//   a:hover {
//     cursor: pointer;
//     color: var(--dark-green);
//     transition: 0.2s ease-in;
//     &:after {
//       width: 100%;
//     }
//   }

//   a.active {
//     color: var(--dark-green);
//     position: relative;
//     &:after {
//       content: "";
//       position: absolute;
//       width: 100%;
//       height: 2px;
//       bottom: -3px;
//       left: 0;
//       background: var(--c-two);
//     }
//   }

//   @media (max-width: 768px) {
//     padding-right: 10px;
//   }
// `;

const Header = () => {
  return (
    <HeaderWrap>
      <LeftSide title="Hi">
        <StyledIcon>
          <a
            href="https://designwithella.com"
            rel="noopener noreferrer"
            className="logo"
            alt="Logo"
          >
            <Icon alt="logo-designwithella" />
          </a>
        </StyledIcon>

        <StyledText href="https://designwithella.com">
          design<span>with</span>ella.com
        </StyledText>
      </LeftSide>
      <></>
      {/* <RightSide>
        <NavLink to="/">Home</NavLink>
        <NavLink to="projects">Work</NavLink>
      </RightSide> */}
    </HeaderWrap>
  );
};

export default Header;
