import "./App.css";

import { Routes, Route } from "react-router-dom";

import GlobalTypography from "./style/Global";
import Header from "./components/Header";
import Home from "./routes/Home";
// import Projects from "./routes/Projects";
import NotFound from "./routes/NotFound";

// import StratAndGo from "./routes/projects/StratAndGo/StratAndGo";
// import RamarooUgc from "./routes/projects/Ramaroo/RamarooUgc";
// import BovobaDashboard from "./routes/projects/Bovoba/BovobaDashboard";
// import BrighteningHero from "./routes/projects/BrighteningHero/BrighteningHero";
// import FitnessApp from "./routes/projects/FitnessApp/FitnessApp";
// import DarkSpectrum from "./routes/projects/DarkSpectrum/DarkSpectrum";
import { StyleSheetManager } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";

function App() {
  return (
    <main className="App">
      <StyleSheetManager
        shouldForwardProp={isPropValid}
        disableVendorPrefixes={false}
      >
        <GlobalTypography />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="projects" element={<Projects />} />
          <Route path="project-strat-and-go" element={<StratAndGo />} />
          <Route
            path="project-ramaroo-ugc-video-player"
            element={<RamarooUgc />}
          />
          <Route
            path="project-bovoba-dashboard"
            element={<BovobaDashboard />}
          />
          <Route
            path="project-brightening-hero"
            element={<BrighteningHero />}
          />
          <Route path="project-fitness-app" element={<FitnessApp />} />
          <Route
            path="project-dark-spectrum-tattoo"
            element={<DarkSpectrum />}
          /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </StyleSheetManager>
    </main>
  );
}

export default App;
